import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, ReplaySubject, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';
import { Auth0AuthParams, Auth0ClientService } from '@app/core/auth0-client.service';
import { WithRequired } from '@app/utils/types';

import { WindowService } from '../utils/window.service';
import { AttemptedPathService } from './attempted-path.service';
import { Auth0AnalyticsService } from './auth0-analytics.service';
import { FeatureFlags } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';
import { LinksService } from './links.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Class AuthService - parent, the api interface that can hande auth, exposed to all the apps
 */
export class AuthService {
  private initialized$ = new ReplaySubject<boolean>(1);
  /**
   * @deprecated - Use AuthService#isLoggedIn$ if you want to check if the user is logged in. This property was added as
   * a stop-gap to allow token access to be removed from this ApiHeaderService later without forcing the refactoring
   * needed to get the app off of using AuthService#isLoggedIn. The refactoring needed is too much to do right now. This
   * property can be removed once all uses of AuthService#isLoggedIn are replaced with AuthService#isLoggedIn$.
   * */
  private _hasToken = false;

  constructor(
    private store: Store,
    private windowService: WindowService,
    private linksService: LinksService,
    private attemptedPathService: AttemptedPathService,
    private auth0AnalyticsService: Auth0AnalyticsService,
    private auth0ClientService: Auth0ClientService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  init() {
    const path = this.windowService.getLocationPathname();
    if (this.attemptedPathService.hasAttemptedPath() && path === this.linksService.home) {
      this.attemptedPathService.navigateToAttemptedPath();
    } else {
      this.attemptedPathService.deleteAttemptedPath();
    }

    this.auth0ClientService
      .setTokenInApiHeaderService$()
      .pipe(
        tap(tokenWasSet => this.broadcastTokenResult(tokenWasSet)),
        tap(() => this.initialized$.next(true)),
      )
      .subscribe();
  }

  linkWithSocialConnection({
    path,
    customAuthorizationParams,
  }: {
    path: string;
    customAuthorizationParams: WithRequired<Auth0AuthParams, 'connection'>;
  }): void {
    this.initialized$
      .pipe(
        tap(() => {
          this.attemptedPathService.setAttemptedPath(path);
          this.auth0ClientService.linkWithSocialConnection(customAuthorizationParams);
        }),
      )
      .subscribe();
  }

  goLogin({
    path,
    fragment,
    customAuthorizationParams,
  }: {
    path?: string;
    fragment?: string;
    customAuthorizationParams?: Auth0AuthParams;
  } = {}): void {
    this._hasToken = false;

    this.initialized$
      .pipe(
        tap(() => {
          if (path) {
            this.attemptedPathService.setAttemptedPath(path);
          }

          if (path?.endsWith(this.linksService.verifyEmail)) {
            this.auth0AnalyticsService.trackLoginStartedFromEmailVerification();
          } else {
            this.auth0AnalyticsService.trackLoginStarted();
          }

          this.auth0ClientService.login({ fragment, customAuthorizationParams });
        }),
      )
      .subscribe();
  }

  logout(returnURI?: string): void {
    this._hasToken = false;

    if (returnURI) {
      this.attemptedPathService.setAttemptedPath(returnURI);
    }

    this.initialized$.pipe(map(() => this.auth0ClientService.logout())).subscribe();
    this.store.dispatch(userLoggedOut());
  }

  setToken(token: string | null): void {
    this.auth0ClientService.persistToken(token);
    this.broadcastTokenResult(!!token);
  }

  /**
   * @deprecated - the authentication process is async. A return of false could mean that the login process is
   * in-flight. Use AuthService#isLoggedIn$ instead. It waits for any auth0-related async processes to complete.
   *
   * @todo Check whether the token's expiration date is still valid
   * @returns
   */
  isLoggedIn(): boolean {
    return this._hasToken;
  }

  isLoggedIn$(): Observable<boolean> {
    return this.initialized$.pipe(
      switchMap(() => this.launchDarklyService.featureFlag$(FeatureFlags.NO_COOKIE_FOR_YOU, false)),
      switchMap(enabled => {
        if (!enabled) {
          return observableOf(this._hasToken);
        }

        return this.auth0ClientService.isAuthenticated$();
      }),
    );
  }

  private broadcastTokenResult(tokenWasSet: boolean) {
    if (tokenWasSet) {
      this.store.dispatch(userLoggedIn());
    }

    this._hasToken = tokenWasSet;
  }
}
