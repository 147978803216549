import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  MembershipStatus,
  MembershipStatusVariables,
} from '@app/registration/__generated__/membership-status-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class MembershipStatusGraphqlService extends Query<MembershipStatus, MembershipStatusVariables> {
  document = gql`
    query MembershipStatus {
      membership {
        id
        isActive
      }
    }
  `;
}
