import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StartToServiceAreaEdgeGraphqlService } from '@app/registration/graph/edges/start-to-service-area-edge-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { ServiceAreaNode } from '@app/registration/graph/nodes/service-area.node';

@Injectable({
  providedIn: 'root',
})
export class StartToServiceAreaEdge implements RegistrationEdge {
  constructor(private graphQLService: StartToServiceAreaEdgeGraphqlService) {}

  nextNode = ServiceAreaNode;

  shouldNavigate$(): Observable<boolean> {
    return this.graphQLService.fetch().pipe(map(result => !result.data?.patient?.serviceArea));
  }
}
