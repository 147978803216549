import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientUnsignedLegalDocs } from '@app/registration/graph/edges/__generated__/unsigned-legal-docs-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class UnsignedLegalDocsGraphqlService extends Query<PatientUnsignedLegalDocs> {
  document = gql`
    query PatientUnsignedLegalDocs {
      patient {
        id
        hasUnsignedLegalDocuments
      }
    }
  `;
}
