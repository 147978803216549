import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { CompleteRegistrationNode } from '@app/registration/graph/nodes/complete-registration.node';
import { MembershipStatusGraphqlService } from '@app/registration/membership-status-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToCompleteRegistrationEdge implements RegistrationEdge {
  nextNode = CompleteRegistrationNode;

  constructor(private membershipStatusGraphqlService: MembershipStatusGraphqlService) {}

  shouldNavigate$(): Observable<boolean> {
    return this.membershipStatusGraphqlService.fetch().pipe(
      filter(result => !!result.data?.membership?.isActive),
      take(1),
      map(() => true),
    );
  }
}
